<template>
  <router-view />
</template>

<script>
export default {
  name: "Index",

  components: {},
};
</script>
